// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-collection-js": () => import("./../src/templates/contentCollection.js" /* webpackChunkName: "component---src-templates-content-collection-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-content-item-js": () => import("./../src/templates/contentItem.js" /* webpackChunkName: "component---src-templates-content-item-js" */),
  "component---src-templates-app-js": () => import("./../src/templates/app.js" /* webpackChunkName: "component---src-templates-app-js" */),
  "component---src-templates-landing-js": () => import("./../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---node-modules-gatsby-plugin-s-3-fake-index-js": () => import("./../../node_modules/gatsby-plugin-s3/fake-index.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-s-3-fake-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

