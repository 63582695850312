import api from '@utils/api';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

const initialState = {
  allItemsCount: 0,
  data: [],
  error: false,
  filters: {},
  hasNextPage: true,
  isFetching: false,
  page: 1,
};

export const SORT_NEWEST = '-created';
export const SORT_MOST_POPULAR = '-visit_count';
export const SORT_OPTIONS = [
  SORT_NEWEST,
  SORT_MOST_POPULAR,
];
export const DEFAULT_SORT_OPTION = SORT_NEWEST;

const getIsFiltering = (
  {
    exclude_id,
    ...currentFilters
  } = {},
  {
    exclude_id: _,
    ...newFilters
  } = {}
) => {
  if (!Object.keys(currentFilters).length) {
    return !!Object.keys(newFilters).length;
  }

  return JSON.stringify(currentFilters) !== JSON.stringify(newFilters);
};
const getPage = ({
  hasNextPage,
  isFiltering,
  isRefetch,
  currentPage,
}) => {
  if (hasNextPage) {
    if (isFiltering) {
      return 2;
    }

    if (!isRefetch) {
      return currentPage + 1;
    }
  }

  return currentPage;
};

const insightsPostsReducer = (state, action) => {
  switch (action.type) {
  case 'FETCH_REQUEST': {
    return ({
      ...state,
      error: null,
      isFetching: true,
    });
  }
  case 'FETCH_SUCCESS': {
    const hasNextPage = !!action.payload.next;
    const {
      isFiltering,
      isRefetch,
      filters,
    } = action.payload;

    return ({
      ...state,
      allItemsCount: action.payload.count,
      data: (isFiltering || isRefetch) ?
        action.payload.results :
        [
          ...state.data,
          ...action.payload.results,
        ],
      filters,
      hasNextPage,
      isFetching: false,
      page: getPage({
        currentPage: state.page,
        hasNextPage,
        isFiltering,
        isRefetch,
      }),
    });
  }
  case 'FETCH_FAILURE': {
    return ({
      ...state,
      error: action.payload.error,
      isFetching: false,
    });
  }
  default: {
    return state;
  }
  }
};

const defaultOptions = {
  pageSize: 9,
  prefetch: true,
};

const fetchRequest = () => ({
  type: 'FETCH_REQUEST',
});

const fetchSuccess = (data) => ({
  payload: data,
  type: 'FETCH_SUCCESS',
});

const fetchFailure = (error) => ({
  payload: {
    error,
  },
  type: 'FETCH_FAILURE',
});

const useInsightsPostsReducer = () => useReducer(insightsPostsReducer, initialState);
const PostsContext = createContext();
const { Provider } = PostsContext;
const useInsightsPostsContext = () => useContext(PostsContext);

export const PostsProvider = ({ children }) => {
  const [
    state,
    dispatch,
  ] = useInsightsPostsReducer();

  return (
    <Provider value={{
      dispatch,
      state,
    }}
    >
      {children}
    </Provider>
  );
};
PostsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useInsightsPosts = (filters, opts = {}) => {
  const {
    state,
    dispatch,
  } = useInsightsPostsContext();

  const options = {
    ...defaultOptions,
    ...opts,
  };

  const parseData = useCallback((data) => {
    const results = data.results.map((item) => ({
      ...item,
      url: `/insights/${item.slug}/`,
    }));

    return {
      ...data,
      results,
    };
  }, []);

  const fetchData = useCallback(async ({
    isRefetch = false,
    params = filters,
    isFiltering,
  } = {}) => {
    dispatch(fetchRequest());

    try {
      const { data } = await api.get('/content-insights/contents', {
        params: {
          ...params,
          page: (isRefetch || isFiltering) ? 1 : state.page,
          page_size: options.pageSize,
        },
      });
      const parsedData = parseData(data);

      dispatch(fetchSuccess({
        ...parsedData,
        filters,
        isFiltering,
        isRefetch,
      }));
    } catch (error) {
      dispatch(fetchFailure(error));
    }
  }, [
    dispatch,
    options.pageSize,
    parseData,
    state.page,
    filters,
  ]);

  useEffect(() => {
    const isFiltering = getIsFiltering(state.filters, filters);

    if (!state.isFetching && !state.error && (
      (options.prefetch && !state.data.length) || isFiltering
    )) {
      fetchData({ isFiltering });
    }
  }, [
    fetchData,
    options.prefetch,
    state.allItemsCount,
    state.data.length,
    state.error,
    state.filters,
    state.isFetching,
    filters,
  ]);

  return [
    state,
    fetchData,
  ];
};

export default useInsightsPosts;
