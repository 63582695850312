/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { PostsProvider } from './src/services/useInsightsPosts';

export const wrapPageElement = ({ element }) => (
  <PostsProvider>
    {element}
  </PostsProvider>
);
