import axios from 'axios';

const apiUrl = process.env.GATSBY_API_URL;
// const username = process.env.GATSBY_BASIC_AUTH_USERNAME;
// const password = process.env.GATSBY_BASIC_AUTH_PASSWORD;

const username = 'merix';
const password = 'merix2020studio';

const config = {
  baseURL: apiUrl,
};

if (apiUrl.includes('merix2020')) {
  config.auth = {
    password,
    username,
  };
}

const api = axios.create(config);

export default api;
